/* PDF Viewer Container */
.pdf-viewer-container {
  width: 100%;
  max-width: 70%; /* Max width for large screens */
  margin: 0 auto; /* Center the PDF viewer */
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-radius: 8px;
  background-color: #f9f9f9; /* Light background */
}

/* Title styling */
.pdf-viewer-container h2 {
  text-align: center;
  font-family: "Space Grotesk", sans-serif; /* Use the preferred font */
  color: #333;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

/* Styling the object or iframe (the PDF content) */
.pdf-viewer-container object,
.pdf-viewer-container iframe {
  width: 100%;
  border: none; /* Remove default borders */
  border-radius: 8px;
  overflow: hidden; /* Ensure no scrolling */
  height: 100vh; /* Placeholder for full height adjustment */
}
