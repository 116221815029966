/* Global styles */
body {
  background-color: #dad9d9;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Raleway", Arial, Helvetica, sans-serif; /* Use Raleway as the default font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

/* Enable smooth scrolling */
html {
  scroll-behavior: smooth; /* Enables smooth scrolling for anchor links */
}

/* Unique class for Raleway font with specific weights */
.raleway-title {
  font-family: "Raleway", sans-serif; /* Use Raleway font */
  font-weight: 700; /* Bold weight */
  font-optical-sizing: auto; /* Enables optical sizing if supported */
  font-style: normal; /* Normal font style */
}

.raleway-body {
  font-family: "Raleway", sans-serif; /* Use Raleway font */
  font-weight: 400; /* Normal weight */
  font-optical-sizing: auto; /* Enables optical sizing if supported */
  font-style: normal; /* Normal font style */
}

/* Backdrop styling */
.circuitry-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./components/_Pictures/Backdrop.jpg"); /* Adjust path as necessary */
  background-size: cover; /* Cover the entire area */
  background-repeat: no-repeat; /* Prevent image repetition */
  opacity: 0.1; /* Set transparency level (adjust as needed) */
  pointer-events: none; /* Allow clicks to go through the backdrop */
  z-index: -1; /* Position behind other content */
}

/* Main content area styles using Grid */
.main-content {
  padding-top: 100px; /* Padding to avoid content being hidden by the fixed header */
  display: grid;
  grid-template-columns: 1fr; /* Single column layout */
  gap: 20px; /* Space between components */
  margin-bottom: 40px;
}


