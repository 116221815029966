.footer {
  background-color: #333; /* Dark background */
  color: #fff; /* White text */
  text-align: center;
  padding: 20px 0;
  width: 100%;
  bottom: 0; /* Sticks the footer to the bottom */
}

.footer p {
  margin: 0;
  font-size: 14px;
}
